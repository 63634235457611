import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components

// ----------------------------------------------------------------------

const IssuesPage = lazy(() => import('src/pages/insights/issues'));
const ValidationsPage = lazy(() => import('src/pages/insights/validations'));
const RequestsPage = lazy(() => import('src/pages/insights/request'));
const UserLovePage = lazy(() => import('src/pages/insights/userLove'));
const ProfilesPage = lazy(() => import('src/pages/insights/profiles'));

// ----------------------------------------------------------------------

export const insightsRoutes = [
  {
    path: 'insights',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'issues', element: <IssuesPage /> },
      { path: 'validations', element: <ValidationsPage /> },
      { path: 'market-problems', element: <RequestsPage /> },
      { path: 'user-love', element: <UserLovePage /> },
      { path: 'profiles', element: <ProfilesPage /> },
    ],
  },
];
