import React, {useEffect, useMemo, useCallback, useReducer, useState} from 'react';
//
import User from 'src/models/user';
import Integration from 'src/models/integration';
import {Product} from "../../../models/product";
import IntegrationOption from "../../../models/integrationOption";
import Company from "../../../models/company";
import {ActionMapType, CreateDataType, DataStateType} from '../types';
import {DataContext} from './data-context';
import { Metric } from '../../../models/metric';


// ----------------------------------------------------------------------
enum Types {
  // INITIAL
  INITIAL = 'INITIAL',
  // ADD
  ADD_INTEGRATION_TYPES = 'ADD_INTEGRATION_TYPES',
  ADD_INTEGRATION_OPTIONS = 'ADD_INTEGRATION_OPTIONS',
  // UPDATE
  UPDATE_COMPANIES = 'UPDATE_COMPANIES',
  UPDATE_USERS = 'UPDATE_USERS',
  UPDATE_PRODUCTS = 'UPDATE_PRODUCTS',
  UPDATE_METRICS = 'UPDATE_METRICS',
  UPDATE_SELECTED_COMPANY = 'UPDATE_SELECTED_COMPANY',
  UPDATE_INTEGRATION_OPTION = 'UPDATE_INTEGRATION_OPTION',
  UPDATE_INTEGRATION = 'UPDATE_INTEGRATION',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
}

type Payload = {
  // INITIAL
  [Types.INITIAL]: DataStateType;
  // ADD
  [Types.ADD_INTEGRATION_TYPES]: {
    integrationTypes: string[],
  };
  [Types.ADD_INTEGRATION_OPTIONS]: {
    integrationOptions: IntegrationOption[],
  };
  // UPDATE
  [Types.UPDATE_COMPANIES]: {
    companies: Company[],
  };
  [Types.UPDATE_USERS]: {
    users: User[],
  };
  [Types.UPDATE_PRODUCTS]: {
    products: Product[],
  };
  [Types.UPDATE_METRICS]: {
    metrics: Metric[],
  };
  [Types.UPDATE_SELECTED_COMPANY]: {
    selectedCompany: string,
  };
  [Types.UPDATE_INTEGRATION_OPTION]: {
    integrationOptions: IntegrationOption[],
  };
  [Types.UPDATE_INTEGRATION]: {
    integration: Integration | null,
  };
  [Types.UPDATE_LANGUAGE]: {
    language: string,
  };
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: DataStateType = {
  companies: [],
  users: [],
  products: [],
  metrics: [],
  selectedCompany: '',
  integrationTypes: [],
  integrationOptions: [],
  integration: null,
  language: '',
};

const reducer = (state: DataStateType, action: Action) => {
  // INITIAL
  if (action.type === Types.INITIAL) {
    return initialState;
  }

  // ADD
  if (action.type === Types.ADD_INTEGRATION_TYPES) {
    return {
      ...state,
      integrationTypes: action.payload.integrationTypes,
    };
  }

  if (action.type === Types.ADD_INTEGRATION_OPTIONS) {
    return {
      ...state,
      integrationOptions: action.payload.integrationOptions,
    };
  }

  // UPDATE
  if (action.type === Types.UPDATE_COMPANIES) {
    return {
      ...state,
      companies: action.payload.companies,
    };
  }

  if (action.type === Types.UPDATE_USERS) {
    return {
      ...state,
      users: action.payload.users,
    };
  }

  if (action.type === Types.UPDATE_PRODUCTS) {
    return {
      ...state,
      products: action.payload.products,
    };
  }

  if (action.type === Types.UPDATE_METRICS) {
    return {
      ...state,
      metrics: action.payload.metrics,
    };
  }

  if (action.type === Types.UPDATE_SELECTED_COMPANY) {
    return {
      ...state,
      selectedCompany: action.payload.selectedCompany,
    };
  }

  if (action.type === Types.UPDATE_INTEGRATION_OPTION) {
    return {
      ...state,
      integrationOptions: action.payload.integrationOptions,
    };
  }

  if (action.type === Types.UPDATE_INTEGRATION) {
    return {
      ...state,
      integration: action.payload.integration,
    };
  }

  if (action.type === Types.UPDATE_LANGUAGE) {
    return {
      ...state,
      language: action.payload.language,
    };
  }

  return state;
};
// ----------------------------------------------------------------------

type DataProviderProps = {
  children: React.ReactNode;
};

export function DataProvider({children}: DataProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [type, setType] = useState<CreateDataType | null>(null);

  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [metric, setMetric] = useState<Metric | null>(null);

  const initialize = useCallback(() => {
    dispatch({
      type: Types.INITIAL,
      payload: initialState,
    });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Add
  const addIntegrationTypes = useCallback((integrationTypes: string[]) => {
    dispatch({
      type: Types.ADD_INTEGRATION_TYPES,
      payload: {
        integrationTypes,
      },
    });
  }, []);

  const addIntegrationOptions = useCallback((integrationOptions: IntegrationOption[]) => {
    dispatch({
      type: Types.ADD_INTEGRATION_OPTIONS,
      payload: {
        integrationOptions,
      },
    });
  }, []);

  // Update
  const updateCompaniesState = useCallback((companies: Company[], init: boolean = false) => {
    const data = init ? companies : [...companies, ...state.companies]
    dispatch({
      type: Types.UPDATE_COMPANIES,
      payload: {
        companies: data,
      },
    });
  }, [state]);

  const updateUsersState = useCallback((users: User[], init: boolean = false) => {
    const data = init ? users : [...users, ...state.users]

    dispatch({
      type: Types.UPDATE_USERS,
      payload: {
        users: data,
      }
    });
  }, [state]);

  const updateProductsState = useCallback((products: Product[], init: boolean = false) => {
    const data = init ? products : [...products, ...state.products]

    dispatch({
      type: Types.UPDATE_PRODUCTS,
      payload: {
        products: data,
      }
    });
  }, [state]);

  const updateMetricsState = useCallback((metrics: Metric[], init: boolean = false) => {
    const data = init ? metrics : [...metrics, ...state.metrics]

    dispatch({
      type: Types.UPDATE_METRICS,
      payload: {
        metrics: data,
      }
    });
  }, [state]);

  const updateSelectedCompanyState = useCallback((selectedCompany: string) => {
    localStorage.setItem('selectedCompany', selectedCompany);
    dispatch({
      type: Types.UPDATE_SELECTED_COMPANY,
      payload: {
        selectedCompany,
      },
    });
  }, []);

  const updateIntegrationOptionState = useCallback((integrationOption: IntegrationOption) => {
    const integrationOptions = state.integrationOptions.map((option) => {
      if (option.id === integrationOption.id) {
        return integrationOption;
      }
      return option;
    });

    dispatch({
      type: Types.UPDATE_INTEGRATION_OPTION,
      payload: {
        integrationOptions,
      }
    });
  }, [state]);

  const updateIntegrationState = useCallback((integration: Integration | null) => {
    dispatch({
      type: Types.UPDATE_INTEGRATION,
      payload: {
        integration,
      }
    });
  }, []);

  const updateLanguage = useCallback((language: string) => {
    localStorage.setItem('lang', language);
    dispatch({
      type: Types.UPDATE_LANGUAGE,
      payload: {
        language,
      }
    });
  }, []);

  // Drawer
  const onToggleDrawer = useCallback((typeToOpen: CreateDataType, editableObj: any = null) => {
    setCompany(null);
    setUser(null);
    setProduct(null);
    setMetric(null);
    setType(typeToOpen);

    if (editableObj) {
      switch (typeToOpen) {
        case CreateDataType.COMPANY:
          setCompany(editableObj as Company);
          break;
        case CreateDataType.USER:
          setUser(editableObj as User);
          break;
        case CreateDataType.PRODUCT:
          setProduct(editableObj as Product);
          break;
        case CreateDataType.METRIC:
          setMetric(editableObj as Metric);
          break;
        default:
          break;
      }
    }

    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
    setType(null);
  }, []);


  const memoizedValue = useMemo(
    () => ({
      ...state,
      // Add
      addIntegrationTypes,
      addIntegrationOptions,
      // Update
      updateCompaniesState,
      updateUsersState,
      updateProductsState,
      updateMetricsState,
      updateSelectedCompanyState,
      updateIntegrationOptionState,
      updateIntegrationState,
      updateLanguage,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
      type,
      company,
      user,
      product,
      metric,
    }),
    [
      state,
      // Add
      addIntegrationTypes,
      addIntegrationOptions,
      // Update
      updateCompaniesState,
      updateUsersState,
      updateProductsState,
      updateMetricsState,
      updateSelectedCompanyState,
      updateIntegrationOptionState,
      updateIntegrationState,
      updateLanguage,
      // Drawer
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      type,
      company,
      user,
      product,
      metric
    ]
  );

  return <DataContext.Provider value={memoizedValue}>{children}</DataContext.Provider>;
}
