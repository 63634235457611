import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
//
import { fileImplementationIcon } from './utils';
import DownloadButton from './download-button';

// import gdoc from 'src/assets/icons/files/googledocs-icon.svg'

// ----------------------------------------------------------------------

type FileIconProps = {
  file?: string;
  label?: string;
  tooltip?: boolean;
  onDownload?: VoidFunction;
  sx?: SxProps<Theme>;
  height?: number | string;
  width?: number | string;
};

export default function IntegrationThumbnail({
                                               file,
                                               label,
                                               tooltip,
                                               onDownload,
                                               sx,
                                               height = '35px',
                                               width = '35px',
                                             }: FileIconProps) {

  const renderContent = (
        <Box
          component="img"
          alt={file}
          src={fileImplementationIcon(file)}
          sx={{
            flexShrink: 0,
            width,
            height,
            ...sx,
          }}
        />
  );


  if (tooltip) {
    return (
      <Tooltip title={label || file}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}
          {onDownload && <DownloadButton onDownload={onDownload} />}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {onDownload && <DownloadButton onDownload={onDownload} />}
    </>
  );
}
