// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const {REACT_APP_VERSION} = process.env;

export const HOST_API = process.env.REACT_APP_HOST_API;

export const HOST_FORM = process.env.REACT_APP_HOST_SDK_FORM;

export const HOST_SURVEY = process.env.REACT_APP_HOST_SDK_SURVEY;

export const HOST_CORO= process.env.REACT_APP_HOST_CORO;

export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const PATHFIX_API = {
  api: process.env.REACT_APP_PATHFIX_API,
  publicKey: process.env.REACT_APP_PATHFIX_PUBLIC_KEY,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
