export type IFilters = {
  id: string[];
  name: string;
  status: any[];
  identity: any[];
  integration: any[];
  productId: any[];
  category: any[];
  feedback: any[];
  startDate: Date | null;
  endDate: Date | null;
  metrics: any[];
  completed: boolean;
  quality: any[];
};

export const defaultFilters: IFilters = {
  id: [],
  name: '',
  status: [],
  identity: [],
  integration: [],
  productId: [],
  category: [],
  feedback: [],
  startDate: null,
  endDate: null,
  metrics: [],
  completed: false,
  quality: [],
};
