/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//
import {useTranslation} from "react-i18next";
import {ConfirmDialogProps} from './types';
import {RouterLink} from "../../routes/components";
import {useSnackbar} from "../snackbar";

// ----------------------------------------------------------------------

export default function ConfirmDialog({
                                        title,
                                        label,
                                        goBack,
                                        openAlert,
                                        error,
                                        content,
                                        action,
                                        open,
                                        onClose,
                                        closeLabel,
                                        ...other
                                      }: ConfirmDialogProps) {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (openAlert) {
      enqueueSnackbar(error ? t('error.pushingFiles') : t('success.pushingFiles'), {
        variant: error ? 'error' : 'success'
      });
    }
  }, [openAlert]);

  return (
    <Dialog fullWidth maxWidth="xs"
            open={open} onClose={onClose} {...other}
    >
      <DialogTitle sx={{pb: 2}}>{title}</DialogTitle>

      {label && <DialogContent sx={{typography: 'body2'}}> {label} </DialogContent>}

      {content && <DialogContent sx={{typography: 'body2'}}> {content} </DialogContent>}

      <DialogActions>
        {!openAlert && action}

        {
          openAlert && goBack && (
            <Button
              variant="outlined"
              color="secondary"
              component={RouterLink} href={goBack}
              sx={{marginLeft: 1.5, marginRight: 1.5}}
            >
              {t('text.buttons.done')}
            </Button>
          )
        }

        {
          onClose && (
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {closeLabel || t('text.buttons.close')}
            </Button>
          )
        }
      </DialogActions>
    </Dialog>
  );
}
