import {clearFilterToSend} from "src/components/dataTable/utils";
import axios, {endpoints} from "../utils/axios";
import getHeader from "./header";
import {Feedback} from "../models/feedback";
import {Insight} from "../models/insight";
import {Profile} from "../models/profile";

export async function getFeedback(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'externalGeneratedAt DESC',
  filters?: any,
): Promise<Feedback[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: {companyId, ...clearFilterToSend(filters, 'feedback')},
      order: order || 'externalGeneratedAt DESC',
      include: [{relation: "user"}]
    };

    const response = await axios.get(`${endpoints.feedback.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getFeedbackByIds(ids: string[], skip: number = 0,
                                       limit: number = 100,
                                       order: string = 'updatedAt DESC',
                                       fields: any = {}): Promise<Feedback[]> {
  if (!ids?.length) return [];
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: {id: {inq: ids}},
      order: order || 'updatedAt DESC',
      fields
    };

    const response = await axios.get(`${endpoints.feedback.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getFeedbackNameList(companyId: string): Promise<Feedback[]> {
  try {
    const header = await getHeader();
    const filter = {
      where: {companyId},
      order: 'name ASC',
      fields: {id:true,name: true}
    };

    const response = await axios.get(`${endpoints.feedback.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      const data: string[] = [];

      response.data.map((item: any) => !data?.includes(item.name) && data.push(item.name));

      return response.data
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getFeedbackCount(companyId: string, where?: any): Promise<number> {
  try {
    const header = await getHeader();
    const filter = {companyId, ...clearFilterToSend(where, 'feedback')};

    const response = await axios.get(`${endpoints.feedback.count}?where=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data?.count;
    }
    return 0
  } catch (e) {
    console.error(e);
    return 0
  }
};

export async function exportFeedback(companyId: string, filters?: any): Promise<any> {
  try {
    const header = await getHeader();
    const filter = {
      where: {companyId, ...clearFilterToSend(filters, 'feedback')},
    };

    const response = await axios.get(`${endpoints.feedback.export}?filter=${JSON.stringify(filter)}`, header);

    if (response.status >= 200 && response.status < 300) {
      return {data:response.data, name:response.headers['content-disposition']?.split('filename=')[1] || ''};
    }
    return null
  } catch (e) {
    console.error(e);
    return null
  }
}

export async function getIssues(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'updatedAt DESC',
  filters?: any,
): Promise<Insight[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: {company: companyId, ...clearFilterToSend(filters, 'issues')},
      order: order || 'updatedAt DESC',
    };

    const response = await axios.get(`${endpoints.feedback.issues.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }

    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getIssuesByFeedbackId(
  companyId: string,
  id: string,
  filters?: any,
  order: string = 'updatedAt DESC',
): Promise<Insight[]> {
  try {
    const header = await getHeader();
    const filter = {
      where: {company: companyId, 'insight.id': id, ...clearFilterToSend(filters, 'issues')},
      order: order || 'updatedAt DESC',
    };

    const response = await axios.get(`${endpoints.feedback.issues.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }

    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getIssuesCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();
    const filter = {company: companyId, ...clearFilterToSend(filters, 'issues')}

    const response = await axios.get(`${endpoints.feedback.issues.count}?where=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data?.count;
    }
    return 0
  } catch (e) {
    console.error(e);
    return 0
  }
};

export async function updateIssues(id: string, issueId: string, data: any): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.patch(`${endpoints.feedback.issues.update(id, issueId)}`, data, header);

    return response.status === 204;

  } catch (e) {
    console.error(e);
    return false
  }
}

export async function exportIssues(companyId: string, filters?: any): Promise<any> {
  try {
    const header = await getHeader();
    const filter = {
      where: {company: companyId, ...clearFilterToSend(filters, 'issues')},
    }

    const response = await axios.get(`${endpoints.feedback.issues.export}?filter=${JSON.stringify(filter)}`, header);

    if (response.status >= 200 && response.status < 300) {
      return {data:response.data, name:response.headers['content-disposition']?.split('filename=')[1] || ''};
    }
    return ''
  } catch (e) {
    console.error(e);
    return ''
  }
}

export async function getValidations(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'updatedAt DESC',
  filters?: any,
): Promise<Insight[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: {company: companyId, ...clearFilterToSend(filters, 'validations')},
      order: order || 'updatedAt DESC',
    };

    const response = await axios.get(`${endpoints.feedback.validations.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getValidationsByFeedbackId(
  companyId: string,
  id: string,
  filters?: any,
  order: string = 'updatedAt DESC',
): Promise<Insight[]> {
  try {
    const header = await getHeader();
    const filter = {
      where: {company: companyId, 'insight.id': id, ...clearFilterToSend(filters, 'validations')},
      order: order || 'updatedAt DESC',
    };

    const response = await axios.get(`${endpoints.feedback.validations.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }

    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getValidationsCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();
    const filter = {company: companyId, ...clearFilterToSend(filters, 'validations')}

    const response = await axios.get(`${endpoints.feedback.validations.count}?where=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data?.count;
    }
    return 0
  } catch (e) {
    console.error(e);
    return 0
  }
};

export async function updateValidations(id: string, validationId: string, data: any): Promise<boolean> {
  try {
    const header = await getHeader();

    const response = await axios.patch(`${endpoints.feedback.validations.update(id, validationId)}`, data, header);

    return response.status === 204;
  } catch (e) {
    console.error(e);
    return false
  }
}

export async function exportValidations(companyId: string, filters?: any): Promise<any> {
  try {
    const header = await getHeader();
    const filter = {
      where: {company: companyId, ...clearFilterToSend(filters, 'validations')},
    }

    const response = await axios.get(`${endpoints.feedback.validations.export}?filter=${JSON.stringify(filter)}`, header);

    if (response.status >= 200 && response.status < 300) {
      return {data:response.data, name:response.headers['content-disposition']?.split('filename=')[1] || ''};;
    }
    return ''
  } catch (e) {
    console.error(e);
    return ''
  }
}

export async function getFeedbacksProfiles(
  companyId: string,
  skip: number = 0,
  limit: number = 50,
  order: string = 'total DESC',
  filters?: any,
): Promise<Profile[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      where: {'insight.company':companyId,...clearFilterToSend(filters, 'profiles')},
      order: order || 'total DESC',
    };

    const response = await axios.get(`${endpoints.feedback.profiles.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
}

export async function getFeedbacksProfilesByFeedbackId(
  companyId: string,
  id: string,
  filters?: any,
  order: string = 'total DESC',
): Promise<Profile[]> {
  try {
    const header = await getHeader();
    const filter = {
      where: {'insight.company':companyId, 'insight.id': id, ...clearFilterToSend(filters, 'profiles')},
      order: order || 'total DESC',
    };

    const response = await axios.get(`${endpoints.feedback.profiles.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }

    return []
  } catch (e) {
    console.error(e);
    return []
  }
}

export async function getFeedbacksProfilesCount(companyId: string, filters?: any): Promise<number> {
  try {
    const header = await getHeader();
    const filter = {'insight.company':companyId,...clearFilterToSend(filters, 'profiles')}

    const response = await axios.get(`${endpoints.feedback.profiles.count}?where=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data?.count;
    }
    return 0
  } catch (e) {
    console.error(e);
    return 0
  }
}

export async function getFeedbacksProfilesFilterData(companyId: string, type: string): Promise<string[]> {
  try {
    const header = await getHeader();
    const filter = {
      where: {'insight.company': companyId},
      order: `${type} ASC`
    }

    const response = await axios.get(`${endpoints.feedback.profiles.list}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      const data = response.data.map((item: any) => item[type]);
      return data.filter((item: any, index: number) => data.indexOf(item) === index);
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
}


export async function getInsights(companyId: string,
                                  skip: number = 0,
                                  limit: number = 50,
                                  order: string = 'externalGeneratedAt DESC',
                                  filters?: any): Promise<Feedback[]> {
  try {
    const header = await getHeader();
    const filter = {
      skip,
      limit,
      order: order || 'externalGeneratedAt DESC',
      // include: [{relation: "user"}],
      where: {companyId, ...clearFilterToSend(filters, 'insights')},
    };

    const response = await axios.get(`${endpoints.feedback.insight}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function getInsightById(id: string): Promise<Feedback[]> {
  try {
    const header = await getHeader();
    const filter = {
      where: {id: {inq: [id]}}
    };

    const response = await axios.get(`${endpoints.feedback.insight}?filter=${JSON.stringify(filter)}`, header);

    if (response.status === 200) {
      return response.data;
    }
    return []
  } catch (e) {
    console.error(e);
    return []
  }
};

export async function reSendFeedbackToProcess(id: string) {
  try {
    const header = await getHeader();
    const response = await axios.patch(`${endpoints.feedback.insightId(id)}`, {}, header);

    return response.status === 204;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteFeedbackById(id: string) {
  try {
    const header = await getHeader();
    const response = await axios.delete(`${endpoints.feedback.id(id)}`, header);

    return response.status >= 200 && response.status < 300;
  } catch (e) {
    console.error(e);
    return false;
  }
}
