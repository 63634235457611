/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect} from "react";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import {ListItemText, Skeleton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import {renderInput} from "./utils";
import {CustomFile, UploadAvatar} from "../upload";
import {fData} from "../../utils/format-number";
import {postIntegrationImageUrl} from "../../services/integrations";


class ImageValue {
  position: number;

  url: string | CustomFile | null;

  value: string;

  constructor(position: number, url: string, value: string) {
    this.position = position;
    this.url = url;
    this.value = value;
  }

}

interface Props {
  values: ImageValue[];
  onChange: (value: any) => void;
  max?: number;
  sx?: any;
}

const MultipleChoseImageList = ({
                                  values,
                                  onChange,
                                  max = 15,
                                  sx = {}
                                }: Props) => {
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const reOrder = (oldPosition: number, newPosition: number) => {
    if (newPosition < 0 || newPosition > values.length) return;

    const list = [...values];
    list[newPosition - 1].position = oldPosition;
    list[oldPosition - 1].position = newPosition;
    list.sort((a, b) => {
      if (a.position === null) return 0;
      if (b.position === null) return 0;
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });

    onChange(list);
  }

  const renderPosition = (value: ImageValue) => value.position !== 0 && (
    <Stack direction="column" alignItems="center" width='40px'>
      <IconButton size="small" disabled={value.position === 1}
                                           onClick={() => reOrder(value.position, value.position - 1)}
                                           sx={{height: '25px', width: '25px'}}>
        <ExpandLess width='20px' height='20px'/>
      </IconButton>
      <ListItemText primary={value.position}/>
      <IconButton size="small" disabled={value.position === values.length}
                  onClick={() => reOrder(value.position, value.position + 1)} sx={{height: '25px', width: '25px'}}>
        <ExpandMore width='20px' height='20px'/>
      </IconButton>
    </Stack>
  );

  const update = (index: number, value: string) => {
    setError('');
    const list = [...values];
    // Check that this new value is not empty and is not duplicated
    if (value === '' || list.some((item, i) => item.value === value && i !== index)) {
      setError(t('error.duplicatedValueDescription'));
      return;
    }
    list[index].value = value;
    onChange(list);
  }

  const handleDropImage = useCallback(async (index: number, acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    const list = [...values];
    if (newFile) {
      setLoading(true);
      const file = Object.assign(newFile, {
        preview: URL.createObjectURL(newFile),
      });

      list[index].url = await postIntegrationImageUrl(file);

      onChange(list);
      setLoading(false);
    }
  }, [onChange, values]);

  const handleDelete = (index: number) => {
    if (values.length === 1) return;

    const list = [...values];
    list?.splice(index, 1)

    // Update position
    list?.forEach((item, i) => {
      item.position = i + 1;
    });

    onChange(list);
  }

  const handleAdd = () => {
    const list = values ? [...values] : [];
    list?.push(new ImageValue(list.length + 1 || 1, '', ''));
    onChange(list);
  }


  useEffect(() => {
    if (values?.length > 0) {
      values.forEach((value, index) => {

        if (typeof value === 'string') {
          try {
            const v = JSON.parse(value);
            values[index] = new ImageValue(v.position, v.url, v.value);
          } catch (e) {
            console.error(e);
          }
        }
      });
    } else if (max === 1) {
      const list = values ? [...values] : [];
      list?.push(new ImageValue(0, '', ''));
      onChange(list);
    }
  }, []);

  return (
    <FormControl fullWidth>
      <Stack direction="column" alignItems="start" spacing={2} sx={{...sx}}>
        <Typography
          variant="caption"
          sx={{
            width: 1,
            textAlign: 'start',
            color: (theme) => theme.palette.error.main,
          }}
        >
          {error}
        </Typography>
        {
          values?.length > 0 && values?.map((value, index: number) => {
            const input = value.value;

            return (
              <Stack key={index} direction="row" alignItems="center" justifyContent="center" spacing={3}>
                {renderPosition(value)}
                {
                  loading ? (
                    <Skeleton variant="rectangular" width={180} height={80}/>
                  ) : (
                    <UploadAvatar
                      file={value.url as CustomFile | string | null}
                      onDrop={(acceptedFiles) => handleDropImage(index, acceptedFiles)}
                      maxSize={1048576 * 4}
                      sx={{width: 180, height: 80}}
                    />
                  )
                }
                {
                  renderInput(
                    input,
                    (i: string, v: any) => {
                      update(index, v);
                    },
                    index.toString(),
                    t('text.inputs.description'),
                    value.value,
                    'text',
                    false,
                    t('text.inputs.description'),
                    {
                      minWidth: '450px',
                    }
                  )
                }
                <IconButton size="small" color="error"
                            disabled={values.length === 1 && index === 0}
                            onClick={() => handleDelete(index)}>
                  <CloseIcon width='20px' height='20px'/>
                </IconButton>
              </Stack>
            )
          })
        }
        <Typography
          variant="caption"
          sx={{
            width: 1,
            textAlign: 'start',
            color: 'text.disabled',
          }}
        >
          {t('text.labels.allowedFormatAndSize') + fData(1048576 * 4)}
        </Typography>
        {max > 1 && <Button variant='outlined'
                            color='success'
                            startIcon={<AddCircleIcon/>}
                            disabled={values?.length === max}
                            onClick={() => handleAdd()}
                            sx={{
                              height: '40px',
                              opacity: 0.95,
                              color: '#20b456',
                              '&:hover': {opacity: 0.9}
                            }}
        >
          {t('text.buttons.add')}
        </Button>
        }
      </Stack>
    </FormControl>
  )
}

export default MultipleChoseImageList;
