import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const ContactPage = lazy(() => import('src/sections/crm/contacts/view'));
const OrganizationPage = lazy(() => import('src/sections/crm/organizations/view'));
const CampaignPage = lazy(() => import('src/sections/crm/campaigns/view'));
const EditCampaignPage = lazy(() => import('src/pages/campaigns/editCampaign'));
const DetailCampaignPage = lazy(() => import('src/pages/campaigns/detailCampaign'));

// ----------------------------------------------------------------------

export const crmRoutes = [
  {
    path: 'crm',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'contacts',element: <ContactPage /> },
      { path: 'organizations',element: <OrganizationPage /> },
      { path: 'campaign',element: <CampaignPage /> },
      { path: 'campaign/create',element: <EditCampaignPage /> },
      { path: 'campaign/edit/:id',element: <EditCampaignPage /> },
      { path: 'campaign/detail/:id',element: <DetailCampaignPage /> },
    ],
  },
];
