/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useMemo, useState} from "react";
// @mui
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import {Button} from "@mui/material";

import Scrollbar from 'src/components/scrollbar';
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import {useAuthContext} from "../../auth/hooks";
import {useDataContext} from "../../components/data/context";
import {getCompanies} from "../../services/companies";
import FilterName from "../../components/dataTable/elements/filterName";
import Company from "../../models/company";


// ----------------------------------------------------------------------
const getCompaniesList = (
  companyId: string,
  page: number,
  filter: any,
  sortBy?: string
) => getCompanies(
  companyId,
  page * 50,
  1000,
  sortBy,
  filter,
  {id: true, name: true},
);


export default function ProjectsPopover() {
  const popover = usePopover();
  const {user} = useAuthContext();
  const {
    selectedCompany,
    updateSelectedCompanyState
  } = useDataContext();

  const [companiesList, setCompaniesList] = useState<Company[]>([]);

  const [strSearch, setStrSearch] = useState<string>('');
  const urlParams = new URLSearchParams(window.location.search);

  const filter = useMemo(() => ({
    status: {inq: ['ACTIVE', 'BLOCKED']}
  }), []);

  const sort = 'name ASC';

  const getCompaniesByString = useCallback((id = strSearch) => {
    getCompaniesList(selectedCompany, 0, {
      name: id,
      ...filter
    }, sort).then(res => {
      setCompaniesList(res);
    })
  }, [strSearch]);

  useEffect(() => {
    getCompaniesList(selectedCompany, 0, filter, sort).then(res => {
      setCompaniesList(res);
    })
  }, []);

  useEffect(() => {
    // Check if selected company is in local storage
    const selectedCompanyFromStorage = localStorage.getItem('selectedCompany');
    // Check if selected company is in a query parameter

    const companyId = urlParams.get('project');
    if (companyId && user?.dataUser?.role === 'ADMIN') {
      updateSelectedCompanyState(companyId)
    } else if (selectedCompanyFromStorage || user?.dataUser?.companyId) {
      // Add or update project query param
      const cId = selectedCompanyFromStorage || user?.dataUser?.companyId
      if (!companyId || companyId !== cId) urlParams.set('project', cId);
      if (user?.dataUser?.role === 'ADMIN') window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
      updateSelectedCompanyState(cId)
    }
  }, [user]);

  const noDataMsg = () => (
    <MenuItem sx={{p: 1}}>
      <ListItemText
        primary="No data"
        primaryTypographyProps={{
          typography: 'subtitle2',
        }}
      />
    </MenuItem>
  )

  return (
    <>
      {
        user?.dataUser?.role === 'ADMIN' ? (
          <Button
            color={popover.open ? 'primary' : 'inherit'}
            onClick={popover.onOpen} sx={{
            ...(popover.open && {
              bgcolor: (theme) => theme.palette.action.selected,
            }),
          }}
            size="small"
          >
            {selectedCompany}
          </Button>
        ) : (<></>)
      }

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 300}}>
        <Typography variant="h6" sx={{p: 1.5}}>
          Projects <Typography component="span">({companiesList.length})</Typography>
        </Typography>

        <FilterName size='medium'
                    filterString={strSearch}
                    onFilters={(name, value) => {
                      setStrSearch(value)
                      getCompaniesByString(value)
                    }}/>

        <Scrollbar sx={{height: 320}}>
          {companiesList?.length === 0 ? noDataMsg() : companiesList.map((company) => (
            <MenuItem key={company.id} sx={{p: 1}}>
              <ListItemText
                primary={company.name}
                primaryTypographyProps={{
                  typography: 'subtitle2',
                  color: company.id === selectedCompany ? 'primary' : 'text.primary',
                }}
                onClick={() => {
                  updateSelectedCompanyState(company.id)
                  popover.onClose()
                  // Add or update selected company in project query param
                  urlParams.set('project', company.id);
                  window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
                  window.location.reload();
                }}
              />
            </MenuItem>
          ))}
        </Scrollbar>
      </CustomPopover>
    </>
  );
}
