import React, {useEffect, useMemo, useCallback, useReducer, useState} from 'react';
//
import {ActionMapType, FeedbackStateType} from '../types';
import {FeedbackContext} from './feedback-context';
import {Feedback} from "../../../models/feedback";
import {Insight} from "../../../models/insight";
import {Count} from "../../../models/count";
import {RequestInsight} from "../../../models/request-insight";
import {Profile} from "../../../models/profile";
import { defaultFilters, IFilters } from '../../../models/filter';

// ----------------------------------------------------------------------
enum Types {
  // INITIAL
  INITIAL = 'INITIAL',
  INIT_FEEDBACK = 'INIT_FEEDBACK',
  INIT_FEEDBACK_FILTER = 'INIT_FEEDBACK_FILTER',
  INIT_ISSUES = 'INIT_ISSUES',
  INIT_VALIDATIONS = 'INIT_VALIDATIONS',
  INIT_REQUESTS = 'INIT_REQUESTS',
  INIT_PROFILES = 'INIT_PROFILES',
  // GET
  GET_FEEDBACK = 'GET_FEEDBACK',
  GET_ISSUES = 'GET_ISSUES',
  GET_VALIDATIONS = 'GET_VALIDATIONS',
  GET_REQUESTS = 'GET_REQUESTS',
  GET_PROFILES = 'GET_PROFILES',
  // ADD
  ADD_FEEDBACK = 'ADD_FEEDBACK',
  ADD_ISSUES = 'ADD_ISSUES',
  ADD_VALIDATIONS = 'ADD_VALIDATIONS',
  ADD_PROFILES = 'ADD_PROFILES',
  // UPDATE
  UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
  UPDATE_FEEDBACK_FILTER = 'UPDATE_FEEDBACK_FILTER',
  UPDATE_ISSUES = 'UPDATE_ISSUES',
  UPDATE_VALIDATIONS = 'UPDATE_VALIDATIONS',
  UPDATE_REQUESTS = 'UPDATE_REQUESTS',
  UPDATE_PROFILES = 'UPDATE_PROFILES',
  UPDATE_SUBMISSIONS_COUNTS = 'UPDATE_SUBMISSIONS_COUNTS',
  UPDATE_DOCUMENTS_COUNTS = 'UPDATE_DOCUMENTS_COUNTS',
  UPDATE_SURVEYS_COUNTS = 'UPDATE_SURVEYS_COUNTS',
  UPDATE_NATIVE_COUNTS = 'UPDATE_NATIVE_COUNTS',
  UPDATE_INSIGHTS_COUNTS = 'UPDATE_INSIGHTS_COUNTS',
  UPDATE_ISSUES_COUNTS = 'UPDATE_ISSUES_COUNTS',
  UPDATE_VALIDATIONS_COUNTS = 'UPDATE_VALIDATIONS_COUNTS',
  UPDATE_REQUESTS_COUNTS = 'UPDATE_REQUESTS_COUNTS',
}

type Payload = {
  // INITIAL
  [Types.INITIAL]: FeedbackStateType;
  [Types.INIT_FEEDBACK]: {
    feedback: Feedback[],
  };
  [Types.INIT_FEEDBACK_FILTER]: {
    filter: IFilters,
  };
  [Types.INIT_ISSUES]: {
    issues: Insight[],
  };
  [Types.INIT_VALIDATIONS]: {
    validations: Insight[],
  };
  [Types.INIT_REQUESTS]: {
    requests: RequestInsight[],
  };
  [Types.INIT_PROFILES]: {
    profiles: Profile[],
  }
  // ADD
  [Types.ADD_FEEDBACK]: {
    feedbacks: Feedback[],
  };
  [Types.ADD_ISSUES]: {
    issues: Insight,
  };
  [Types.ADD_VALIDATIONS]: {
    validations: Insight,
  };
  [Types.ADD_PROFILES]: {
    profiles: Profile[],
  }
  // GET
  [Types.GET_FEEDBACK]: {
    feedback: Feedback[],
  };
  [Types.GET_ISSUES]: {
    issues: Insight[],
  };
  [Types.GET_VALIDATIONS]: {
    validations: Insight[],
  };
  [Types.GET_REQUESTS]: {
    requests: RequestInsight[],
  };
  [Types.GET_PROFILES]: {
    profiles: Profile[],
  }
  // UPDATE
  [Types.UPDATE_FEEDBACK]: {
    feedback: Feedback,
  };
  [Types.UPDATE_FEEDBACK_FILTER]: {
    filter: IFilters,
  };
  [Types.UPDATE_ISSUES]: {
    issue: Insight,
  };
  [Types.UPDATE_VALIDATIONS]: {
    validation: Insight,
  };
  [Types.UPDATE_REQUESTS]: {
    request: RequestInsight,
  };
  [Types.UPDATE_PROFILES]: {
    profile: Profile,
  }
  [Types.UPDATE_SUBMISSIONS_COUNTS]: {
    submissions: Count
  };
  [Types.UPDATE_DOCUMENTS_COUNTS]: {
    documents: Count
  };
  [Types.UPDATE_SURVEYS_COUNTS]: {
    surveys: Count
  };
  [Types.UPDATE_NATIVE_COUNTS]: {
    native: Count
  };
  [Types.UPDATE_INSIGHTS_COUNTS]: {
    insights: Count
  };
  [Types.UPDATE_ISSUES_COUNTS]: {
    issues: Count
  };
  [Types.UPDATE_VALIDATIONS_COUNTS]: {
    validations: Count
  };
  [Types.UPDATE_REQUESTS_COUNTS]: {
    requests: Count
  };

};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: FeedbackStateType = {
  feedback: [],
  feedbackFilters: {
    ...defaultFilters,
    status: [
      {id: 'REGISTERED', label: 'REGISTERED'},
      {id: 'DONE', label: 'DONE'},
      {id: 'PARTIAL', label: 'PARTIAL'},
      {id: 'PROCESSING', label: 'PROCESSING'},
    ]
  },
  issues: [],
  validations: [],
  requests: [],
  profiles: [],
  submissionsCounts: {
    submissions: {count: 0, loading: true},
    documents: {count: 0, loading: true},
    surveys: {count: 0, loading: true},
    native: {count: 0, loading: true},
  },
  insightsCounts: {
    insights: {count: 0, loading: true},
    issues: {count: 0, loading: true},
    validations: {count: 0, loading: true},
    requests: {count: 0, loading: true},
  }
};

const reducer = (state: FeedbackStateType, action: Action) => {
  // INITIAL
  if (action.type === Types.INITIAL) {
    return initialState;
  }

  if (action.type === Types.INIT_FEEDBACK) {
    return {
      ...state,
      feedback: action.payload.feedback,
    };
  }

  if (action.type === Types.INIT_FEEDBACK_FILTER) {
    return {
      ...state,
      feedbackFilters: action.payload.filter,
    };
  }

  if (action.type === Types.INIT_ISSUES) {
    return {
      ...state,
      issues: action.payload.issues,
    };
  }

  if (action.type === Types.INIT_VALIDATIONS) {
    return {
      ...state,
      validations: action.payload.validations,
    };
  }

  if (action.type === Types.INIT_REQUESTS) {
    return {
      ...state,
      requests: action.payload.requests,
    };
  }

  if (action.type === Types.INIT_PROFILES) {
    return {
      ...state,
      profiles: action.payload.profiles,
    };
  }

  // GET
  if (action.type === Types.GET_FEEDBACK) {
    return {
      ...state,
      feedback: [...state.feedback, ...action.payload.feedback],
    };
  }

  if (action.type === Types.GET_ISSUES) {
    return {
      ...state,
      issues: [...state.issues, ...action.payload.issues],
    };
  }

  if (action.type === Types.GET_VALIDATIONS) {
    return {
      ...state,
      validations: [...state.validations, ...action.payload.validations],
    };
  }

  if (action.type === Types.GET_REQUESTS) {
    return {
      ...state,
      requests: [...state.requests, ...action.payload.requests],
    };
  }

  if (action.type === Types.GET_PROFILES) {
    return {
      ...state,
      profiles: [...state.profiles, ...action.payload.profiles],
    };
  }

  // ADD
  if (action.type === Types.ADD_FEEDBACK) {
    return {
      ...state,
      feedback: [...state.feedback, ...action.payload.feedbacks],
    }

  }

  if (action.type === Types.ADD_ISSUES) {
    const issuesById = state.issues.filter((f) => f.id === action.payload.issues.id);
    if (issuesById.length === 0) {
      return {
        ...state,
      }
    }

    return {
      ...state,
      issues: [...state.issues, action.payload.issues],
    }
  }

  if (action.type === Types.ADD_VALIDATIONS) {
    const validationsById = state.validations.filter((f) => f.id === action.payload.validations.id);
    if (validationsById.length === 0) {
      return {
        ...state,
      }
    }

    return {
      ...state,
      validations: [...state.validations, action.payload.validations],
    }
  }

  if (action.type === Types.ADD_PROFILES) {
    return {
      ...state,
      profiles: [...state.profiles, ...action.payload.profiles],
    }
  }

  // UPDATE
  if (action.type === Types.UPDATE_FEEDBACK) {
    // Update issue in issues list with the new one
    const feedbacks = state.feedback.map((feedback) => {
        if (feedback.id === action.payload.feedback.id) {
          return action.payload.feedback;
        }
        return feedback;
      }
    );

    return {
      ...state,
      feedbacks,
    }
  }

  if (action.type === Types.UPDATE_FEEDBACK_FILTER) {
    return {
      ...state,
      feedbackFilters: action.payload.filter,
    }
  }

  if (action.type === Types.UPDATE_ISSUES) {
    // Update issue in issues list with the new one
    const issues = state.issues.map((issue) => {
        if (issue.issues_id === action.payload.issue.issues_id) {
          return action.payload.issue;
        }
        return issue;
      }
    );

    return {
      ...state,
      issues,
    }
  }

  if (action.type === Types.UPDATE_VALIDATIONS) {
    // Update validation in validations list with the new one
    const validations = state.validations.map((validation) => {
        if (validation.values_id === action.payload.validation.values_id) {
          return action.payload.validation;
        }
        return validation;
      }
    );

    return {
      ...state,
      validations,
    }
  }

  if (action.type === Types.UPDATE_REQUESTS) {
    // Update request in requests list with the new one
    const requests = state.requests.map((request) => {
        if (request.id === action.payload.request.id) {
          return action.payload.request;
        }
        return request;
      }
    );

    return {
      ...state,
      requests,
    }
  }

  if (action.type === Types.UPDATE_PROFILES) {
    // Update profile in profiles list with the new one
    const profiles = state.profiles.map((profile) => {
        if (profile.email === action.payload.profile.email) {
          return action.payload.profile;
        }
        return profile;
      }
    );

    return {
      ...state,
      profiles,
    }
  }

  if (action.type === Types.UPDATE_SUBMISSIONS_COUNTS) {
    return {
      ...state,
      submissionsCounts: {
        ...state.submissionsCounts,
        submissions: action.payload.submissions,
      }
    }
  }

  if (action.type === Types.UPDATE_DOCUMENTS_COUNTS) {
    return {
      ...state,
      submissionsCounts: {
        ...state.submissionsCounts,
        documents: action.payload.documents,
      }
    }
  }

  if (action.type === Types.UPDATE_SURVEYS_COUNTS) {
    return {
      ...state,
      submissionsCounts: {
        ...state.submissionsCounts,
        surveys: action.payload.surveys,
      }
    }
  }

  if (action.type === Types.UPDATE_NATIVE_COUNTS) {
    return {
      ...state,
      submissionsCounts: {
        ...state.submissionsCounts,
        native: action.payload.native,
      }
    }
  }

  if (action.type === Types.UPDATE_INSIGHTS_COUNTS) {
    return {
      ...state,
      insightsCounts: {
        ...state.insightsCounts,
        insights: action.payload.insights,
      }
    }
  }

  if (action.type === Types.UPDATE_ISSUES_COUNTS) {
    return {
      ...state,
      insightsCounts: {
        ...state.insightsCounts,
        issues: action.payload.issues,
      }
    }
  }

  if (action.type === Types.UPDATE_VALIDATIONS_COUNTS) {
    return {
      ...state,
      insightsCounts: {
        ...state.insightsCounts,
        validations: action.payload.validations,
      }
    }
  }

  if (action.type === Types.UPDATE_REQUESTS_COUNTS) {
    return {
      ...state,
      insightsCounts: {
        ...state.insightsCounts,
        requests: action.payload.requests,
      }
    }
  }

  return state;
};
// ----------------------------------------------------------------------

type FeedbackProviderProps = {
  children: React.ReactNode;
};

export function FeedbackProvider({children}: FeedbackProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(null);
  const [showQuestions, setShowQuestions] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const initialize = useCallback(() => {
    dispatch({
      type: Types.INITIAL,
      payload: initialState,
    });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Update
  const updateFeedbacksState = useCallback((feedback: Feedback[], init: boolean = false) => {
    if (!init && feedback?.length > 0) {
      dispatch({
        type: Types.GET_FEEDBACK,
        payload: {
          feedback,
        },
      });
    } else if (init) {
      dispatch({
        type: Types.INIT_FEEDBACK,
        payload: {
          feedback,
        },
      });
    }
  }, []);

  const updateFeedbackState = useCallback((feedback: Feedback) => {
    dispatch({
      type: Types.UPDATE_FEEDBACK,
      payload: {
        feedback,
      },
    });
  }, []);

  const updateFeedbackFilterState = useCallback((filter: IFilters) => {
    dispatch({
      type: Types.UPDATE_FEEDBACK_FILTER,
      payload: {
        filter,
      },
    });
  }, []);

  const updateIssuesState = useCallback((issues: Insight[], init: boolean = false) => {
    dispatch({
      type: !init ? Types.GET_ISSUES : Types.INIT_ISSUES,
      payload: {
        issues,
      },
    });
  }, []);

  const updateIssueState = useCallback((issue: Insight) => {
    dispatch({
      type: Types.UPDATE_ISSUES,
      payload: {
        issue,
      },
    });
  }, []);

  const updateValidationsState = useCallback((validations: Insight[], init: boolean = false) => {
    if (!init && validations?.length > 0) {
      dispatch({
        type: Types.GET_VALIDATIONS,
        payload: {
          validations,
        },
      });
    } else if (init) {
      dispatch({
        type: Types.INIT_VALIDATIONS,
        payload: {
          validations,
        },
      });
    }
  }, []);

  const updateValidationState = useCallback((validation: Insight) => {
    dispatch({
      type: Types.UPDATE_VALIDATIONS,
      payload: {
        validation,
      },
    });
  }, []);

  const updateRequestsState = useCallback((requests: RequestInsight[], init: boolean = false) => {
    if (!init && requests?.length > 0) {
      dispatch({
        type: Types.GET_REQUESTS,
        payload: {
          requests,
        },
      });
    } else if (init) {
      dispatch({
        type: Types.INIT_REQUESTS,
        payload: {
          requests,
        },
      });
    }
  }, []);

  const updateRequestState = useCallback((request: RequestInsight) => {
    dispatch({
      type: Types.UPDATE_REQUESTS,
      payload: {
        request,
      },
    });
  }, []);

  const updateProfilesState = useCallback((profiles: Profile[], init: boolean = false) => {
    if (!init && profiles?.length > 0) {
      dispatch({
        type: Types.GET_PROFILES,
        payload: {
          profiles,
        },
      });
    } else if (init) {
      dispatch({
        type: Types.INIT_PROFILES,
        payload: {
          profiles,
        },
      });
    }
  }, []);

  const updateProfileState = useCallback((profile: Profile) => {
    dispatch({
      type: Types.UPDATE_PROFILES,
      payload: {
        profile,
      },
    });
  }, []);

  // Update Counts State Submissions
  const updateSubmissionsCountsState = useCallback((submissionsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_SUBMISSIONS_COUNTS,
      payload: {
        submissions: submissionsCounts
      }
    })
  }, []);

  const updateDocumentsCountsState = useCallback((submissionsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_DOCUMENTS_COUNTS,
      payload: {
        documents: submissionsCounts
      }
    })
  }, []);

  const updateSurveysCountsState = useCallback((submissionsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_SURVEYS_COUNTS,
      payload: {
        surveys: submissionsCounts
      }
    })
  }, []);

  const updateNativeCountsState = useCallback((submissionsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_NATIVE_COUNTS,
      payload: {
        native: submissionsCounts
      }
    })
  }, []);

  // Update Counts State Insights

  const updateInsightsCountsState = useCallback((insightsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_INSIGHTS_COUNTS,
      payload: {
        insights: insightsCounts
      }
    })
  }, []);

  const updateIssuesCountsState = useCallback((insightsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_ISSUES_COUNTS,
      payload: {
        issues: insightsCounts
      }
    })
  }, []);

  const updateValidationsCountsState = useCallback((insightsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_VALIDATIONS_COUNTS,
      payload: {
        validations: insightsCounts
      }
    })
  }, []);

  const updateRequestsCountsState = useCallback((insightsCounts: Count) => {
    dispatch({
      type: Types.UPDATE_REQUESTS_COUNTS,
      payload: {
        requests: insightsCounts
      }
    })
  }, []);

  // Add
  const addFeedbackToState = useCallback((feedbacks: Feedback[]) => {
    dispatch({
      type: Types.ADD_FEEDBACK,
      payload: {
        feedbacks,
      },
    });
  }, []);

  const addIssueToState = useCallback((issues: Insight) => {
    dispatch({
      type: Types.ADD_ISSUES,
      payload: {
        issues,
      },
    });
  }, []);

  const addValidationToState = useCallback((validations: Insight) => {
    dispatch({
      type: Types.ADD_VALIDATIONS,
      payload: {
        validations,
      },
    });
  }, []);

  const addProfileToState = useCallback((profiles: Profile[]) => {
    dispatch({
      type: Types.ADD_PROFILES,
      payload: {
        profiles,
      },
    });
  }, []);

  // Drawer
  const onToggleDrawer = useCallback((feedback: Feedback, showQuestionsTab: boolean = false) => {
    setSelectedFeedback(feedback);
    setShowQuestions(showQuestionsTab);
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
    setSelectedFeedback(null);
    setShowQuestions(false);
  }, []);

  const forceRefreshPage = useCallback((v:boolean) => {
    setRefresh(v);
  }, []);


  const memoizedValue = useMemo(
    () => ({
      ...state,
      // Update
      updateFeedbacksState,
      updateFeedbackState,
      updateFeedbackFilterState,
      updateIssuesState,
      updateIssueState,
      updateValidationsState,
      updateValidationState,
      updateRequestsState,
      updateRequestState,
      updateProfilesState,
      updateProfileState,
      // Update Counts State Submissions
      updateSubmissionsCountsState,
      updateDocumentsCountsState,
      updateSurveysCountsState,
      updateNativeCountsState,
      // Update Counts State Insights
      updateInsightsCountsState,
      updateIssuesCountsState,
      updateValidationsCountsState,
      updateRequestsCountsState,
      // Add
      addFeedbackToState,
      addIssueToState,
      addValidationToState,
      addProfileToState,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
      selectedFeedback,
      showQuestions,
      // Refresh page
      refresh,
      refreshPage: forceRefreshPage
    }),
    [
      state,
      // Update
      updateFeedbacksState,
      updateFeedbackState,
      updateFeedbackFilterState,
      updateIssuesState,
      updateIssueState,
      updateValidationsState,
      updateValidationState,
      updateRequestsState,
      updateRequestState,
      updateProfilesState,
      updateProfileState,
      // Update Counts State Submissions
      updateSubmissionsCountsState,
      updateDocumentsCountsState,
      updateSurveysCountsState,
      updateNativeCountsState,
      // Update Counts State Insights
      updateInsightsCountsState,
      updateIssuesCountsState,
      updateValidationsCountsState,
      updateRequestsCountsState,
      // Add
      addFeedbackToState,
      addIssueToState,
      addValidationToState,
      addProfileToState,
      // Drawer
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      selectedFeedback,
      showQuestions,
      // Refresh page
      refresh,
      forceRefreshPage
    ]
  );

  return <FeedbackContext.Provider value={memoizedValue}>{children}</FeedbackContext.Provider>;
}
