import { createContext, useContext } from 'react';
//
import { FeedbackContextProps } from '../types';

// ----------------------------------------------------------------------

export const FeedbackContext = createContext({} as FeedbackContextProps);

export const useFeedbackContext = () => {
  const context = useContext(FeedbackContext);

  if (!context) throw new Error('useFeedbackContext must be use inside FeedbackProvider');

  return context;
};
