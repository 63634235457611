import { createContext, useContext } from 'react';
//
import { DataContextProps } from '../types';

// ----------------------------------------------------------------------

export const DataContext = createContext({} as DataContextProps);

export const useDataContext = () => {
  const context = useContext(DataContext);

  if (!context) throw new Error('useDataContext must be use inside DataProvider');

  return context;
};
