/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
// @mui
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Scrollbar from 'src/components/scrollbar';
// models
import {Profile} from 'src/models/profile';
import {Feedback, IAnswers, IMetrics} from "../../../models/feedback";
// components
import {renderChip} from "../../dataTable/new-utils";

import EmptyContent from "../../empty-content";
import {InsightsItemsList} from './insights-items-list';
import {ProfilesItemsList} from "./profile-items-list";
import {InsightData} from "../../../models/insight-data";


interface Props {
  feedback: Feedback;
  showQuestions?: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const FeedbackExtendedDetails = ({feedback, showQuestions = false}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(showQuestions ? 2 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderEmptyContent = (
    <EmptyContent
      filled
      title={t('error.noData')}
      sx={{
        py: 10,
      }}
    />
  )

  const parseNameToTitle = (name: string, clean: boolean = true) =>
    // change the first letter to uppercase and remove - to space
    clean ?
      name.charAt(0).toUpperCase() + name.slice(1).replace(/-/g, ' ') :
      name.charAt(0).toUpperCase() + name.slice(1)
  ;

  const renderList = (list: InsightData[]) => (
    <Scrollbar sx={{minHeight: 500, pt: 2}}>
      {list?.length > 0 ? <InsightsItemsList insights={list}/> : renderEmptyContent}
    </Scrollbar>
  )

  const renderProfiles = (profileList: Profile[]) => (
    <Scrollbar sx={{minHeight: 500, pt: 2}}>
      {profileList?.length > 0 ? <ProfilesItemsList profiles={profileList}/> : renderEmptyContent}
    </Scrollbar>
  )

  const renderData = (items: IAnswers[] | IMetrics[], clean:boolean) => (
    <Scrollbar sx={{minHeight: 500, pt: 2}}>
      {
        items?.length > 0 ? items.map((item: any, index) => (
          <Stack key={item.key || index} sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
            <Typography variant="subtitle1"
                        sx={{flexGrow: 1, color: theme.palette.text.secondary}}>
              {
                item.key && typeof item.key === 'string' && `${item.key?.charAt(0).toUpperCase()}${item.key?.slice(1)}` ||
                item.name && parseNameToTitle(item.name, clean) ||
                index
              }
            </Typography>
            <Stack direction='column' alignItems='start' justifyContent='start' width='100%' sx={{pb: 1}}>
              <Typography variant="body2"
                          sx={{color: theme.palette.text.secondary}}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  item?.value ||
                  item?.values?.length > 0 ?
                    (
                      item?.values?.toString() === "" ?
                        '-' :
                        item?.values?.toString()?.replace(/,/g, ', ')
                    ) : '-' ||
                    JSON.stringify(item)}
              </Typography>
            </Stack>
          </Stack>
        )) : renderEmptyContent
      }
    </Scrollbar>
  );

  return (
    <Box sx={{width: '100%', pr: 1, pl: 2.5}} justifyContent='center'>
      <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 1}}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("text.labels.issues")} {...a11yProps(0)}
               icon={renderChip("error", feedback.issues.length.toString())}
               iconPosition="end"
          />
          <Tab label={t("text.labels.validations")} {...a11yProps(1)}
               icon={renderChip("success", feedback.values.length.toString())}
               iconPosition="end"
          />
          <Tab label={t("text.labels.metrics")} {...a11yProps(2)}
               icon={renderChip("primary", feedback.metrics?.length?.toString() || "0")}
               iconPosition="end"
          />
          <Tab label={t("text.labels.profile")} {...a11yProps(3)}
               icon={renderChip("info", feedback.profile?.length?.toString() || "0")}
               iconPosition="end"
          />
          <Tab label={t("text.labels.metadata")} {...a11yProps(4)}
               icon={renderChip("warning", feedback.metadata?.length.toString() || "0")}
               iconPosition="end"
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {
          renderList(feedback.issues)
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {
          renderList(feedback.values)
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {renderData(feedback.metrics, false)}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {
          renderProfiles(feedback.profile)
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        {renderData(feedback.metadata, true)}
      </CustomTabPanel>
    </Box>
  );
}
