import {forwardRef} from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, {BoxProps} from '@mui/material/Box';
// routes

import {RouterLink} from 'src/routes/components';

import logoPath from 'src/assets/icons/logo-dark.png';
import logoLongPath from 'src/assets/icons/logo-dark-long.png';
import logoLightPath from 'src/assets/icons/logo-light.png';
import logoLightLongPath from 'src/assets/icons/logo-light-long.png';
import {useSettingsContext} from "../settings";


// settings.themeMode
// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  long?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({disabledLink = false, long = false, sx, ...other}, ref) => {
    const style = !long ? {width: 40, height: 40, cursor: 'pointer'} : {width: 220, cursor: 'pointer', marginRight: 5};
    const settings = useSettingsContext();

    const getSrcLogo = () =>{
      if (settings.themeMode === 'dark'){
        return !long ? logoLightPath : logoLightLongPath;
      }
      return !long ? logoPath : logoLongPath;
    }

    const logo = (
      <Box
        component="img"
        src={getSrcLogo()}
        sx={{...style, ...sx}}/>
    );


    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{display: 'contents'}}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
