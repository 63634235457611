import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/users/list'));

// ----------------------------------------------------------------------

export const usersRoutes = [
  {
    path: 'users',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
    ],
  },
];
