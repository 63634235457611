export const getLabel = (lang: string) => {
  switch (lang) {
    case 'en':
      return 'English';
    case 'es':
      return 'Spanish';
    case 'fr':
      return 'French';
    case 'de':
      return 'German';
    case 'it':
      return 'Italian';
    case 'pt':
      return 'Portuguese';
    case 'ru':
      return 'Russian';
    case 'zh':
      return 'Chinese';
    case 'ja':
      return 'Japanese';
    case 'ar':
      return 'Arabic';
    case 'hi':
      return 'Hindi';
    case 'ko':
      return 'Korean';
    case 'da':
      return 'Danish';
    case 'fi':
      return 'Finnish';
    case 'no':
      return 'Norwegian';
    case 'sv':
      return 'Swedish';
    case 'bn':
      return 'Bengali';
    default:
      return lang;
  }
}
