import {useEffect} from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from "@mui/material/Typography";
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
// hooks
import {useMockedUser} from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import {usePathname} from 'src/routes/hooks';
import {NavSectionVertical} from 'src/components/nav-section';
//
import {NAV} from '../config-layout';
import {useNavData} from './config-navigation';
import {NavToggleButton} from '../_common';
import {REACT_APP_VERSION} from "../../config-global";

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({openNav, onCloseNav}: Props) {
  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  const version = REACT_APP_VERSION || '0.0.0'

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo long sx={{mt: 3, ml: 4, mb: 3}}/>

      <NavSectionVertical
        data={navData}
        config={{
          currentRole: 'admin',
        }}
      />

      <Box sx={{flexGrow: 1}}/>
    </Scrollbar>
  );

  const renderVersion = (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          color: 'text.secondary',
          paddingBottom: 1,
      }}
      >v {version}</Typography>
    </Box>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV.W_VERTICAL},
        backgroundColor: 'background.paper',
      }}
    >
      <NavToggleButton/>
      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
          {renderVersion}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
