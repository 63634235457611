// ----------------------------------------------------------------------

import Company from "../../models/company";
import User from "../../models/user";
import {Product} from "../../models/product";
import IntegrationOption from "../../models/integrationOption";
import Integration from "../../models/integration";
import { Metric } from '../../models/metric';

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
      type: Key;
    }
    : {
      type: Key;
      payload: M[Key];
    };
};

export enum CreateDataType {
  USER = 'USER',
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
  METRIC = 'METRIC',
}

export type DataStateType = {
  companies: Company[];
  users: User[];
  products: Product[];
  metrics: Metric[];
  selectedCompany: string;
  integrationTypes: string[];
  integrationOptions: IntegrationOption[];
  integration: Integration | null;
  language: string;
};

export type DataContextProps = DataStateType & {
  // Add
  addIntegrationOptions: (option: IntegrationOption[]) => void;
  addIntegrationTypes: (type: string[]) => void;
  // Update
  updateCompaniesState: (companies: Company[], init?: boolean) => void;
  updateUsersState: (users: User[], init?: boolean) => void;
  updateProductsState: (products: Product[], init?: boolean) => void;
  updateMetricsState: (metrics: Metric[], init?: boolean) => void;
  updateSelectedCompanyState: (companyId: string) => void;
  updateIntegrationOptionState: (options: IntegrationOption) => void;
  updateIntegrationState: (integration: Integration | null) => void;
  updateLanguage: (language: string) => void;
  // Drawer
  open: boolean;
  onToggle: (type: CreateDataType, editableObj?: any) => void;
  onClose: VoidFunction;
  type: CreateDataType | null;
  company: Company | null;
  user: User | null;
  product: Product | null;
  metric: Metric | null;
};
