import React from 'react';
// @mui
import { Button, FormControl, ListItemText, OutlinedInput, Select, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { getStatusIcon, getStatusImageColor, getStatusText } from '../../utils/format-status';
import IconCustom from '../icon-custome/iconCustom';
import IntegrationThumbnail from '../file-thumbnail/integration-thumbnail';
import { RouterLink } from '../../routes/components';
import {fileImplementationIcon} from "../file-thumbnail";


// Filter
// ----------------------------------------------------------------------
export type IFilterValue = string | string[] | number | Date | null;

export const handleFilters = (
  name: string,
  value: IFilterValue,
  setFilters: (prevState: any) => void,
) => {
  setFilters((prevState: any) => ({
    ...prevState,
    [name]: value,
  }));
};

// Components
// ----------------------------------------------------------------------

export const renderDate = (date: string | Date) => (
  <ListItemText
    primary={format(new Date(date), 'dd MMM yyyy')}
    secondary={format(new Date(date), 'p')}
    primaryTypographyProps={{ typography: 'body2' }}
    secondaryTypographyProps={{
      mt: 0.5,
      component: 'span',
      typography: 'caption',
    }}
    sx={{ pl: 0.5 }}
  />
);

export const renderUserData = (primary:string, secondary:string) => (
  <ListItemText
    primary={primary}
    secondary={secondary}
    primaryTypographyProps={{ typography: 'body2' }}
    secondaryTypographyProps={{
      mt: 0.5,
      component: 'span',
      typography: 'caption',
    }}
  />
);

export const renderUserIconAndName = (icon: string = 'profile', name?: string, email?: string) => (
  <Stack direction="row" alignItems="center" justifyContent="start">
    <IntegrationThumbnail file={icon} tooltip={false} sx={{ width: 30, height: 30, marginRight: 1 }} />
    <Tooltip title={name}>
      <ListItemText
        primary={name}
        secondary={email}
        primaryTypographyProps={{ typography: 'body2' }}
        secondaryTypographyProps={{
          mt: 0.5,
          component: 'span',
          typography: 'caption',
        }}
      />
    </Tooltip>
  </Stack>
);

export const renderThumbnailIconAndText = (identity?: string, name?: string) => (
  <Stack direction="row" alignItems="center" justifyContent="start">
    <IntegrationThumbnail file={identity} sx={{ width: 30, height: 30, marginRight: 1 }} />
    <Tooltip title={name}>
      <Typography
        variant="inherit"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
      >
        {name}
      </Typography>
    </Tooltip>
  </Stack>
);

export const renderThumbnailIcon = (identity?: string, label?: string, tooltip:boolean = true) => (
  <Stack alignItems="center" justifyContent="center">
    <IntegrationThumbnail file={identity}
                          label={label}
                          tooltip={tooltip}
                          sx={{ width: 30, height: 30, marginRight: 1 }} />
  </Stack>
);

export const renderLogoPreview = (logoUrl: string, sx?: any) => (
  <Stack alignItems="center" justifyContent="center">
  <Box
    component="img"
    alt={logoUrl}
    src={logoUrl}
    sx={{
      flexShrink: 0,
      width: 'auto',
      height: '65px',
      ...sx,
    }}
  />
  </Stack>
);

export const renderColorPreview = (color: string) => (
  <Stack alignItems="center" justifyContent="center">
    <Chip
      sx={{ backgroundColor: color, height: 30, width: 30 }}
    />
  </Stack>
);

export const renderThumbnailIconAndItem = (identity?: string, name?: string, label?: string) => (
  <Stack direction="row" alignItems="center" justifyContent="start">
    <IntegrationThumbnail file={identity} sx={{ width: 30, height: 30, marginRight: 2 }} />
    <Stack direction="column" alignItems="center" justifyContent="center" sx={{ mb: 1 }}>
      <Tooltip title={name}>
        <Typography
          variant="inherit"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            mt: 1,
          }}
        >
          {name}
        </Typography>
      </Tooltip>
      {label && renderChip('default', label)}
    </Stack>
  </Stack>
);

export const renderText = (text?: string, maxLines: number = 2, sx = {}) => (
  <Tooltip title={text}>
    <Typography
      variant="inherit"
      sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        pl: 1,
        ...sx,
      }}
    >
      {text}
    </Typography>
  </Tooltip>
);

export const renderChip = (
  color?: 'warning' | 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'info',
  label?: string,
  m = 0,
) => label && (
  <Stack alignItems="center" justifyContent="center" m={m}>
    <Tooltip title={label}>
      <Chip
        sx={{
          height: 25,
          minWidth: 30,
          maxWidth: 300,
          fontSize: 12,
          fontWeight: 600,
          padding: '4px 0 4px 0',
          overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip',
        }}
        color={color || 'default'}
        size="small"
        variant="soft"
        label={label}
      />
    </Tooltip>
  </Stack>
);

export const renderChipList = (list: string[],
                               height: string | number = 80,
                               direction: 'row' | 'column' = 'row',
                               color: 'warning' | 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | undefined = 'default',
) =>
  <List
    sx={{
      display: 'flex',
      flexDirection: direction,
      padding: 0,
      position: 'relative',
      overflow: 'auto',
      maxHeight: height,
      width: '100%',
      '&::-webkit-scrollbar': {
        background: 'transparent',
      },
    }}
  >
    {list?.map((item, index) => (
      <div key={index}>
        {renderChip(color, item, 1)}
      </div>
    ))}
  </List>;

export const getStatusImage = (status?: string, extended: boolean = false) => {
  const label = getStatusText(status);
  const file = getStatusIcon(status);
  const color = getStatusImageColor(status);

  return (
    <Tooltip title={label}>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <IconCustom iconImage={file} color={color} />
        {extended && <Typography
          variant="inherit"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            fontWeight: 400,
            justifyContent: 'center',
            alignItems: 'center',
            pl: 1,
          }}
        >
          {label}
        </Typography>}
      </Stack>
    </Tooltip>
  );
};

export const renderElementLink = (external_url?: string) => external_url && (
  <IconButton
    href={external_url}
    target="_blank"
    color="primary"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <IconCustom iconImage="link" size={20} color="primary" />
  </IconButton>
);

export const renderActionButton = (onClick: () => void, label: string) => (
  <Button
    aria-label={label}
    variant="text"
    onClick={onClick}
    color="primary"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography
      variant="inherit"
      sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        fontWeight: 400,
      }}
    >{label}</Typography>
  </Button>
);


export const renderSelect = (value: any, options: any[], handleChange: (value: any) => void) => (
  <div>
    <FormControl sx={{ m: 1, width: 'auto', maxWidth: 150, border: 'none' }} size="small" variant="outlined">
      <Select
        value={value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        input={<OutlinedInput sx={{
          borderRadius: 1,
          border: 'none',
          maxHeight: 26,
          backgroundColor: (theme) => theme.palette.background.default,
        }} />}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={(selected) => <Typography
          variant="inherit"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            fontSize: '13px',
            fontFamily: 'Public Sans',
            fontWeight: 500,
            lineHeight: '18px',
            wordWrap: 'break-word',
            py: 1,
            border: 'none',
          }}
        >
          {selected}
        </Typography>}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{}}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);
