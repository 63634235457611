import SvgColor from '../svg-color';
import { StyledIcon } from '../nav-section/vertical/styles';

interface Props {
  iconImage: string;
  color?: string;
  size?: number;
}

const iconCustom = ({ iconImage, color, size = 20 }: Props) => {
  const src = () => {
    switch (iconImage) {
      case 'processing':
        return `/assets/icons/files/${iconImage}.gif`;
      case 'market problems':
        return `/assets/icons/files/requests.png`;
      default:
        return `/assets/icons/files/${iconImage}.png`;
    }
  };
  const icon = () => (
    <SvgColor src={src()}
              sx={{
                width: size,
                height: size,
                margin: 'auto',
                color: color || 'text.secondary',
              }} />
  );

  const renderIcon = () => <StyledIcon size={size}>{icon()}</StyledIcon>;

  return renderIcon();
};

export default iconCustom;
