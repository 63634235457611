/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import { FormGroup } from '@mui/material';
import Button from '@mui/material/Button';
// services
import { useTranslation } from 'react-i18next';
import {getCompanies, patchCompany, postCompany} from 'src/services/companies';
import { patchUser, postUser } from 'src/services/users';
import { postProduct, patchProduct, getProducts } from 'src/services/products';

// theme
import { paper } from 'src/theme/css';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from '../../iconify';
//
import { useDataContext } from '../context';
import { CreateDataType } from '../types';
import { OptionConfig, OptionType } from '../../../models/optionConfig';
// eslint-disable-next-line import/no-cycle
import DynamicRequestForm from '../../dynamicRequestForm/DynamicRequestForm';
import { forms } from './forms';
import { patchMetric, postMetric } from '../../../services/metrics';
import { Product } from '../../../models/product';
import Company from "../../../models/company";
import {getIntegrationLang} from "../../../services/integrations";


//


// ----------------------------------------------------------------------

export default function DataDrawer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedCompany,
    open, onClose,
    type,
    product,
    user,
    company,
    metric,
  } = useDataContext();

  const [sendLoading, setSendLoading] = useState<boolean>(false);

  const [requestOptions, setRequestOptions] = useState<OptionConfig[]>([]);
  const [requestForm, setRequestForm] = useState<Record<string, any>>();

  const [productList, setProductList] = useState<Product[]>([]);
  const [companiesList, setCompaniesList] = useState<Company[]>([]);
  const [language, setLanguage] = useState<string[]>(['en', 'es']);

  const margins = { pr: 1, pl: 2.5 };

  const verticals = [
    'TECHNOLOGY',
    'AUTOMOTIVE',
    'FINANCE',
    'HEALTHCARE',
    'ENERGY',
    'RETAIL',
    'TELECOMMUNICATIONS',
    'AEROSPACE_AND_DEFENSE',
    'CONSUMER_GOODS',
    'PHARMACEUTICALS',
    'ENTERTAINMENT',
    'FOOD_AND_BEVERAGE',
    'REAL_ESTATE',
    'TRANSPORTATION_AND_LOGISTICS',
    'UTILITIES',
    'GAMBLING',
    'GENERAL',
  ];

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ ...margins, py: 2 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {
          product?.name ||
          user?.name ||
          company?.name ||
          metric?.name ||
          `${t('text.pageTitles.createNew')} ${t(`text.inputs.${type?.toLowerCase()}`)}`
        }
      </Typography>

      <IconButton onClick={() => {
        setRequestOptions([]);
        onClose();
      }}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const sendItems = async () => {
    setSendLoading(true);

    if (requestForm) {
      let response = false;
      switch (type) {
        case CreateDataType.COMPANY:
          requestForm.allowedDomains = requestForm.allowedDomains && typeof requestForm.allowedDomains === 'string' ?
            requestForm.allowedDomains.split(',') : [];

          requestForm.allowedEmails = requestForm.allowedEmails && typeof requestForm.allowedEmails === 'string' ?
            requestForm.allowedEmails.split(',') : [];

          if (company) {
            response = await patchCompany(company.id, requestForm as Record<string, string>);
          } else {
            response = await postCompany(requestForm as Record<string, string>);
          }
          break;
        case CreateDataType.USER:
          if (user) {
            response = await patchUser(user.id, requestForm as Record<string, string>);
          } else {
            response = await postUser(requestForm as Record<string, string>);
          }
          break;
        case CreateDataType.PRODUCT:
          requestForm.ipAllowed = requestForm.ipAllowed && typeof requestForm.ipAllowed === 'string' ?
            requestForm.ipAllowed.split(',') : [];

          requestForm.originAllowed = requestForm.originAllowed && typeof requestForm.originAllowed === 'string' ?
            requestForm.originAllowed?.split(',') : [];

          requestForm.categories = requestForm.categories && typeof requestForm.categories === 'string' ?
            requestForm.categories?.split(',') : [];

          if (requestForm.batchSize === 0) requestForm.batchSize = null
          if (requestForm.thresholdSim === 0) {
            requestForm.thresholdSim = null;
          } else if (requestForm.thresholdSim > 1) {
            requestForm.thresholdSim /= 100;
          }
          if (requestForm.lowerBound === 0) requestForm.lowerBound = null
          if (requestForm.upperBound === 0) requestForm.upperBound = null

          if (product) {
            response = await patchProduct(requestForm as Record<string, string>, product.id);
          } else {
            // remove categories if empty
            delete requestForm.categories;
            response = await postProduct(requestForm as Record<string, string>);
          }
          break;
        case CreateDataType.METRIC:
          requestForm.values = requestForm.values && typeof requestForm.values === 'string' ?
            requestForm.values.split(',') : [];
          requestForm.examples = requestForm.examples && typeof requestForm.examples === 'string' ?
            requestForm.examples.split(',') : [];
          requestForm.companyId = selectedCompany;

          if (metric) {
            response = await patchMetric(requestForm as Record<string, string>, metric.id);
          } else {
            response = await postMetric(requestForm as Record<string, string>);
          }
          break;
        default:
          break;
      }

      if (!response) {
        enqueueSnackbar(t('error.defaultError'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('success.created'), { variant: 'success' });
        setRequestOptions([]);
        onClose();
      }
    }
    setSendLoading(false);
  };

  const setRequestQuestions = useCallback(() => {
    switch (type) {
      case CreateDataType.COMPANY:
        setRequestOptions(
          forms.company(company, verticals),
        );
        break;
      case CreateDataType.USER:
        setRequestOptions(
          forms.user(user, selectedCompany, companiesList),
        );

        if (!user) {
          setRequestOptions((prev) => [
            ...prev,
            // email
            {
              ref: 'email',
              name: {
                en: 'Email',
                es: 'Correo',
                da: 'Email',
                fi: 'Sähköposti',
                no: 'E-post',
                sv: 'E-post',
              },
              type: OptionType.TEXT,
              require: true,
              defaultValues: [''],
              possibleValues: [],
            },
            // password
            {
              ref: 'password',
              name: {
                en: 'Password',
                es: 'Contraseña',
                da: 'Adgangskode',
                fi: 'Salasana',
                no: 'Passord',
                sv: 'Lösenord',
              },
              type: OptionType.PASSWORD,
              require: true,
              defaultValues: [''],
              possibleValues: [],
            },
          ]);
        }
        break;
      case CreateDataType.PRODUCT:
        setRequestOptions(
          forms.product(product, selectedCompany, companiesList, language),
        );
        if (!product) {
          setRequestOptions((prev) =>
            prev.filter((request) => ![
              'company',
              'thresholdSim',
              'lowerBound',
              'upperBound',
              'lang',
              'longTailDeletion',
              'thresholdUpdate',
              'highImpactUpdate'
            ].includes(request.ref)),
          );
        }
        break;
      case CreateDataType.METRIC:
        setRequestOptions(
          forms.metric(metric, selectedCompany, productList),
        );
        break;
      default:
        setRequestOptions([]);
        break;
    }
  }, [type, companiesList, setRequestOptions]);

  const renderForm = requestOptions?.length > 0 && (
    <DynamicRequestForm
      requests={requestOptions}
      onChangeRequest={(form) => setRequestForm(form)}
      width={580}
      sx={{ mb: 5 }}
    />
  );

  const renderSendButton = (
    <Button
      variant="contained"
      color="inherit"
      disabled={
        sendLoading || !requestForm ||
        // check if requestForm values are empty and is not required in requestOptions
        requestOptions.some((request) => request.require && requestForm[request.ref] === '')
      }
      onClick={sendItems}
      sx={{ m: 'auto', width: '90%' }}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        (product?.id || company?.id || user?.id) ?
          t(`${sendLoading ? 'text.labels.updating' : 'text.buttons.update'}`) :
          t(`${sendLoading ? 'text.labels.sending' : 'text.buttons.send'}`)
      }
    </Button>
  );

  useEffect(() => {
    setRequestQuestions();
  }, [type, productList]);

  useEffect(() => {
    getProducts(
      selectedCompany,
      0,
      10000,
      '',
      {},
      { id: true, name: true },
    ).then((res) => {
      setProductList(res);
    });
    getCompanies(
      selectedCompany,
      0,
      10000,
      '',
      {},
      {id: true, name: true},
    ).then((res) => {
      setCompaniesList(res);
    })
    getIntegrationLang().then((response) => setLanguage(response));
  }, [selectedCompany]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.paper }),
          width: 600,
        },
      }}
    >
      {renderHead}
      <Divider sx={{ borderStyle: 'solid' }} />
      <FormGroup sx={{
        padding: 1,
      }}>
        {renderForm}
        {renderSendButton}
      </FormGroup>
    </Drawer>
  );
}
