export const parseDate = (date: Date, end: boolean = false) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);

  return `${newDate.toISOString().slice(0, -1).split('T')[0]}T${end ? '23:59:59' : '00:00:00'}`;
};

export const clearFilterToSend = (filter: any, type?: string) => {
  if (!filter) return {};
  const filterToSend: any = {};
  if (filter.id && filter.id.length > 0) {
    if (type === 'companies') {
      filterToSend.id = filter.id;
    } else if (type === 'issues' || type === 'validations') {
      filterToSend['insight.id'] = { inq: [...filter.id] };
    } else if (type === 'insights') {
      filterToSend['requestMessages.id'] = { inq: [...filter.id] };
    } else {
      filterToSend.id = { inq: [...filter.id] };
    }
  }
  if (filter.name) {
    if (type === 'issues') {
      filterToSend.or = [
        { 'issues.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        { 'issues.reason': { ilike: encodeURIComponent(`%${filter.name}%`) } },
      ];
    } else if (type === 'validations') {
      filterToSend.or = [
        { 'values.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        { 'values.reason': { ilike: encodeURIComponent(`%${filter.name}%`) } },
      ];
    } else if (type === 'insights') {
      filterToSend['requestMessages.name'] = { ilike: encodeURIComponent(`%${filter.name}%`) };
    } else if (type === 'feedback') {
      filterToSend.or = [
        { name: { ilike: encodeURIComponent(`%${filter.name}%`) } },
        { summary: { ilike: encodeURIComponent(`%${filter.name}%`) } },
        { id: { ilike: encodeURIComponent(`%${filter.name}%`) } },
      ];
    } else if (type === 'profiles') {
      filterToSend.or = [
        { 'profile.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        { 'profile.email': { ilike: encodeURIComponent(`%${filter.name}%`) } },
      ];
    } else if (type === 'sessions') {
      filterToSend.or = [
        { 'crmContact.name': { ilike: encodeURIComponent(`%${filter.name}%`) } },
        { 'crmContact.email': { ilike: encodeURIComponent(`%${filter.name}%`) } },
      ];
    } else {
      filterToSend.name = { ilike: encodeURIComponent(`%${filter.name}%`) };
    }
  }
  if (filter.email?.length > 0) {
    if (type === 'profiles') {
      filterToSend['profile.email'] = filter.email;
    }
  }
  if (filter.status?.length > 0) {
    if (type === 'feedback') {
      filterToSend.status = { inq: filter.status.map((status: any) => status?.id) };
    } else if (type === 'sessions') {
      filterToSend.status = { inq: filter.status };
    } else {
      filterToSend.status = filter.status;
    }
  }
  if (filter.integration?.filter((integration: any) => integration?.id !== undefined).length > 0) {
    const integrations = filter.integration.filter(
      (integration: any) => integration?.id !== undefined
    );
    if (['insights'].includes(type || '')) {
      filterToSend['insights.integration'] = {
        inq: integrations.map((integration: any) => integration?.id),
      };
    } else if (['issues', 'validations', 'metrics'].includes(type || '')) {
      filterToSend['insight.integration'] = {
        inq: integrations.map((integration: any) => integration?.id),
      };
    } else {
      filterToSend.integrationId = { inq: integrations.map((integration: any) => integration?.id) };
    }
  }
  if (filter.identity?.length > 0 || type === 'integration') {
    if (type === 'insights') {
      filterToSend['insights.identity'] = filter.identity;
    } else {
      filterToSend.identity = filter.identity;
    }
  }
  if (filter.productId?.length > 0) {
    if (['issues', 'validations'].includes(type || '')) {
      filterToSend['insight.product'] =
        { inq: filter.productId.map((product: any) => product?.id) } || filter.productId;
    } else if (['insights'].includes(type || '')) {
      filterToSend.product =
        { inq: filter.productId.map((product: any) => product?.id) } || filter.productId;
    } else {
      filterToSend.productId =
        { inq: filter.productId.map((product: any) => product?.id) } || filter.productId;
    }
  }
  if (filter.category?.filter((category: any) => category?.id !== undefined)?.length > 0) {
    if (type === 'insights') {
      filterToSend['insights.category'] = {
        inq:
          filter.category
            .filter((category: any) => category?.id !== undefined)
            .map((category: any) => category?.id) || filter.category,
      };
    } else {
      filterToSend.category = {
        inq:
          filter.category.map((category: any) => category?.id?.trim()) ||
          filter.category.map((category: any) => category?.trim()),
      };
    }
  }

  if (filter.metrics?.filter((metric: any) => metric !== null).length > 0) {
    const metrics: any[] = [];
    if (['feedback', 'issues', 'validations'].includes(type || '')) {
      filter.metrics?.forEach((metric: any) => {
        if (metric) {
          const index = metrics.findIndex((m: any) => m.metrics_name === metric?.group);
          if (index === -1) {
            metrics.push({ metrics_name: metric?.group, metrics_values: [metric?.value] });
          } else {
            metrics[index].metrics_values.push(metric?.value);
          }
        }
      });
    } else {
      filter.metrics?.forEach((metric: any) => {
        if (metric) {
          const index = metrics.findIndex((m: any) => m.metricsName === metric?.group);
          if (index === -1) {
            metrics.push({ metricsName: metric?.group, metricsValues: [metric?.value] });
          } else {
            metrics[index].metricsValues.push(metric?.value);
          }
        }
      });
    }

    if (['issues', 'validations', 'insights', 'feedback'].includes(type || '')) {
      filterToSend.metrics = metrics;
    } else {
      filterToSend['insights.metrics.values'] = metrics;
    }
  }
  if (filter.feedback?.length > 0) {
    switch (type) {
      case 'issues':
      case 'validations':
        filterToSend.id = { inq: filter.feedback.map((feedback: any) => feedback?.id) };
        break;
      case 'metrics':
        filterToSend['insight.id'] = { inq: filter.feedback.map((feedback: any) => feedback?.id) };
        break;
      case 'insights':
        filterToSend['insights.feedbackId'] = {
          inq: filter.feedback.map((feedback: any) => feedback?.id),
        };
        break;
      default:
        filterToSend['insight.name'] = {
          inq: filter.feedback.map((feedback: any) => feedback?.id),
        };
    }
  }
  if (filter.integrationId) {
    filterToSend.integrationId = filter.integrationId;
  }
  if (filter.type?.length > 0) {
    filterToSend.type = filter.type;
  }
  if (filter.startDate && filter.endDate) {
    if (type === 'insights') {
      filterToSend['insights.externalGeneratedAt'] = {
        between: [parseDate(filter.startDate), parseDate(filter.endDate, true)],
      };
    } else if (type === 'feedback') {
      filterToSend.externalGeneratedAt = {
        between: [parseDate(filter.startDate), parseDate(filter.endDate, true)],
      };
    } else if (type === 'metrics') {
      filterToSend['insight.externalGeneratedAt'] = {
        between: [
          filter.startDate.toISOString().slice(0, -1),
          filter.endDate.toISOString().slice(0, -1),
        ],
      };
    } else {
      filterToSend['insight.externalGeneratedAt'] = {
        between: [parseDate(filter.startDate), parseDate(filter.endDate, true)],
      };
    }
  } else if (filter.startDate) {
    if (type === 'insights') {
      filterToSend['insights.externalGeneratedAt'] = {
        gt: parseDate(filter.startDate),
      };
    } else {
      filterToSend.externalGeneratedAt = {
        gt: parseDate(filter.startDate),
      };
    }
    filterToSend.externalGeneratedAt = {
      gt: parseDate(filter.startDate),
    };
  } else if (filter.endDate) {
    if (type === 'insights') {
      filterToSend['insights.externalGeneratedAt'] = {
        gt: parseDate(filter.endDate, true),
      };
    } else {
      filterToSend.externalGeneratedAt = {
        lt: parseDate(filter.endDate, true),
      };
    }
  }
  if (filter['user.email']) {
    filterToSend['user.email'] = filter['user.email'];
  }
  if (filter['user.id']) {
    filterToSend['user.id'] = filter['user.id'];
  }
  if (filter.phase) {
    filterToSend.phase = filter.phase;
  }
  if (filter.userId) {
    filterToSend.userId = filter.userId;
  }

  if (filter.completed) {
    filterToSend.finished = filter.completed;
  }

  if (filter.quality?.length > 0) {
    filterToSend.quality = { inq: filter.quality };
  }

  if (filter.and) {
    filterToSend.and = filter.and;
  }
  if (filter.or) {
    filterToSend.or = filter.or;
  }

  return filterToSend;
};
